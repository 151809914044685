.search-select-popover {
    overflow: hidden;
    --min-width: 60%;
}
.search-select-list {
    --max-height: 200px;
    height: 290px;
    overflow-y: scroll;
}
.search-select-item {
    cursor: pointer;
}

.search-select-item-label {
    font-size: 14px;
}
