.ticket-action-label {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  background-color: var(--ion-color-light);
  border-right: 5px solid var(--ion-color-primary);
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}
.sorted {
  font-size: 17px;
  color: black;
  font-weight: bolder;
  cursor: pointer;
}
.ticket-item {
  border-left: 5px solid transparent;
}
.ticket-item.selected {
  border-left: 5px solid var(--ion-color-primary);
}
.ticket-item-price {
  text-align: right;
  font-weight: bold;
  font-size: 16px;
}
.ticket-item-description {
  font-size: 14px;
  margin-bottom: 0px;
}
.ticket-totals-mobile {
  display: none;
}
.ticket-modifier-label {
  font-size: 11px;
  color: var(--ion-color-medium);
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
}

@media screen and (min-width: 360px) and (max-width: 991px) {
  .ticket-totals-mobile {
    display: block;
    background-color: var(--ion-color-light);
    color: var(--ion-color-primary);
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .ticket-totals-mobile .price {
    text-align: right;
  }
  .ticket-item-price {
    display: none;
  }
  .ticket-item-description {
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .ticket-action-label {
    font-size: 14px;
  }
}

.images {
  padding-right: 5%;
}

.centerContent {
  text-align: center;
}
