.messageItem {
  font-size: 17px;
  margin-bottom: 10px;
  /* margin-left: 20px; */
  cursor: pointer;
  transition: transform 0.2s; /* Animation */
}
.messageItem:hover {
  transform: scale(1.1);
}
