.main-menu-item {
  height: 120px;
  display: flex;
  font-size: 28px;
}
.main-menu-item-icon {
  font-size: 28px;
}
.header1 {
  font-size: 20px;
}
.header {
  text-align: center;
  font-size: 20px;
}
