.t-grid-item {
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: var(--ion-color-light);
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 10px;
  margin: 0px 7px 7px 0px;
  -webkit-box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.62);
  -moz-box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.62);
  box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.62);
}

.t-grid-item-text {
  z-index: 1;
  white-space: normal;
  color: #fff;
  /* color: #09fbd3; */
  font-size: 8px;
  background-color: rgba(0, 0, 0, 0.85);
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  font-weight: bold;
  padding: 5px;
  text-align: center;
}

.t-grid-item-text-v2 {
  text-align: center;
  font-size: 35px;
  margin-top: -5px;
}

.t-grid-item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 10px;
}

.t-grid-items {
  margin-top: 10px;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}

.t-grid {
  clear: both;
}

.t-grid-item.selected {
  background-color: #09fbd3;
}
.t-grid-item.selected .t-grid-item-text {
  background-color: rgb(9, 251, 211);
  color: #333;
}

@media screen and (min-width: 360px) and (max-width: 991px) {
  .t-grid-items {
    padding-left: 0px;
    margin-top: 5px;
  }
  .t-grid-item {
    width: 23%;
    height: 80px;
    margin: 0px 0px 7px 5px;
  }
  .t-grid-item-text {
    font-size: 9px;
    max-height: auto;
    padding: 5px;
  }
}

@media screen and (min-width: 667px) and (max-width: 991px) {
  .t-grid-items {
    padding-left: 10px;
  }
  .t-grid-item {
    width: 18%;
    height: 110px;
    margin: 0px 0px 7px 7px;
  }
}
